import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CONSULTA_FINANCEIRO,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FATURAMENTO,
  FATURAMENTO_NFE,
  GERENTE_OSORIO,
  LABORATORIO,
  LOGISTICA,
  PUBLICIDADE,
  EMISSOR_NFE,
  GESTAO,
  TINTOMETRICO,
  CAIXA,
  VENDEDOR,
  FINANCEIRO_GERENTE,
  OPERACIONAL,
  SUPERVISOR_NACIONAL,
  EXPEDICAO,
} from "@/constants/profiles.js";

export default [
  {
    path: "/estoque",
    name: "estoque",
    component: () => import("@/components/home/MenuEstoque.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros",
    name: "estoque.cadastros",
    component: () => import("@/components/estoque/cadastros/SubmenuEstoqueCadastros.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros/produtos",
    name: "produtos",
    component: () => import("@/components/estoque/cadastros/produto/produtos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        PUBLICIDADE,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros/depositos",
    name: "depositos",
    component: () => import("@/components/estoque/cadastros/depositos/gerenciar-depositos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        PUBLICIDADE,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros/depositos/novo",
    name: "depositos.novo",
    component: () => import("@/components/estoque/cadastros/depositos/novo-deposito.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        PUBLICIDADE,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros/depositos/editar/:id",
    name: "depositos.edit",
    component: () => import("@/components/estoque/cadastros/depositos/editar-deposito.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        PUBLICIDADE,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros/depositos/registro",
    name: "depositos.registro",
    component: () => import("@/components/estoque/cadastros/depositos/registro-movimentacao.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        PUBLICIDADE,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
        EXPEDICAO,
      ],
    },
  },
  {
    path: "/estoque/cadastros/produtos/cadastro",
    name: "produtos.cadastro",
    component: () => import("@/components/estoque/cadastros/produto/editor-produto-geral.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/produtos/historico/:codpro",
    name: "produtos.estoque.historico",
    component: () => import("@/components/estoque/cadastros/produto/historico-estoque.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/produtos/custos-por-preco",
    name: "produtos.estoque.rentabilidade",
    component: () => import("@/components/estoque/cadastros/produto/rentabilidade-produto.vue"),
    meta: {
      authorize: [CONTROLADORIA, ADMIN_SYS, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/estoque/cadastros/produtos/editar/:id",
    name: "produtos.edit",
    component: () => import("@/components/estoque/cadastros/produto/editor-produto-geral.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        LOGISTICA,
        PUBLICIDADE,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/unidades-medida",
    name: "unidadeMedida",
    component: () => import("@/components/estoque/cadastros/unidade-medida/unidade-medida.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/unidades-medida/editar/:id",
    name: "unidade-medida.edit",
    component: () => import("@/components/estoque/cadastros/unidade-medida/form-unidade-medida.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/unidades-medida/cadastro",
    name: "unidade-medida.cadastro",
    component: () => import("@/components/estoque/cadastros/unidade-medida/form-unidade-medida.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/linhas",
    name: "linhas",
    component: () => import("@/components/estoque/cadastros/linhas/linhas.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/linhas/editar/:id",
    name: "linhas.edit",
    component: () => import("@/components/estoque/cadastros/linhas/form-linha.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/linhas/cadastro",
    name: "linhas.cadastro",
    component: () => import("@/components/estoque/cadastros/linhas/form-linha.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/produtos-grupos",
    name: "estoque.produtos.grupos",
    component: () => import("@/components/estoque/cadastros/grupo-produto/grupos-produtos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        PUBLICIDADE,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/volumes",
    name: "estoque.volumes",
    component: () => import("@/components/estoque/cadastros/volume/volumes.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/volumes/cadastro",
    name: "estoque.volumes.cadastro",
    component: () => import("@/components/estoque/cadastros/volume/form-volume.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/cadastros/volumes/editar/:id",
    name: "estoque.volume.edit",
    component: () => import("@/components/estoque/cadastros/volume/form-volume.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        EMISSOR_NFE,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/inventarios",
    name: "estoque.inventarios",
    component: () => import("@/components/estoque/inventario/home-inventario.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/inventarios/produtos",
    name: "estoque.inventarios.produtos",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/inventario/inventarios-produtos.vue"),
  },
  {
    path: "/estoque/inventarios/produtos/editar/:id",
    name: "estoque.inventarios.produtos.edit",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/inventario/produtos-edit.vue"),
  },
  {
    path: "/estoque/inventarios/produtos-restantes",
    name: "estoque.inventarios.produtos.restantes",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/inventario/inventarios-produtos-restantes.vue"),
  },
  {
    path: "/estoque/inventarios/materia-prima",
    name: "estoque.inventarios.insumos",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/inventario/inventarios-insumos.vue"),
  },
  {
    path: "/estoque/inventarios/materia-prima/editar/:id",
    name: "estoque.inventarios.insumos.edit",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/inventario/insumos-edit.vue"),
  },
  {
    path: "/estoque/relatorios",
    name: "estoque.relatorios",
    component: () => import("@/components/estoque/relatorios/home-relatorios.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/relatorios/custos-totais",
    name: "estoque.relatorios.custo",
    component: () => import("@/components/estoque/relatorios/produtos-custo.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/relatorios/custos-com-indiretos-totais",
    name: "estoque.relatorios.custo-com-indiretos",
    component: () => import("@/components/estoque/relatorios/produtos-custo-com-indiretos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/estoque/inventarios/materia-prima-restantes",
    name: "estoque.inventarios.insumos.restantes",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        LOGISTICA,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/inventario/inventarios-insumos-restantes.vue"),
  },
];
