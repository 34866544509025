import { ref } from "vue";
import axios from "axios";

const defaultSettings = {
  modoCalculo: "automatico",
};

const settings = ref<Record<string, any>>({});
const localSettings = ref<Record<string, any>>({
  ...defaultSettings,
  ...JSON.parse(localStorage.getItem("localSettings") || "{}"),
});

export default {
  state: settings,
  localState: localSettings,

  async fetchSettings() {
    const { data } = await axios.get("/api/system-settings");
    settings.value = data;
  },

  getSetting(key: string) {
    return settings.value[key];
  },

  getLocalSetting(key: string) {
    return localSettings.value[key] ?? defaultSettings[key as keyof typeof defaultSettings];
  },

  setLocalSetting(key: string, value: any) {
    localSettings.value[key] = value;
    localStorage.setItem("localSettings", JSON.stringify(localSettings.value));
  },

  removeLocalSetting(key: string) {
    delete localSettings.value[key];
    if (key in defaultSettings) {
      localSettings.value[key] = defaultSettings[key as keyof typeof defaultSettings];
    }
    localStorage.setItem("localSettings", JSON.stringify(localSettings.value));
  },

  clearLocalSettings() {
    localSettings.value = { ...defaultSettings };
    localStorage.removeItem("localSettings");
  },
};
