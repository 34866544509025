import { ADMIN_SYS, ALMOXARIFADO } from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/almoxarifado/MenuAlmoxarifado.vue"),
    name: "almoxarifado.menu",
    path: "/almoxarifado",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroProdutoAlmoxarifado.vue"),
    name: "almoxarifado.cadastro.produto",
    path: "/almoxarifado/cadastro/produto",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroProdutoAlmoxarifado.vue"),
    name: "almoxarifado.edit.produto",
    path: "/almoxarifado/edit/produto/:id",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroMovimentacaoAlmoxarifado.vue"),
    name: "almoxarifado.edit.movimentacao",
    path: "/almoxarifado/edit/movimentacao/:id",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/GerenciarProdutosAlmoxarifado.vue"),
    name: "almoxarifado.gerenciar.produtos",
    path: "/almoxarifado/produtos/gerenciar",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/CadastroMovimentacaoAlmoxarifado.vue"),
    name: "almoxarifado.cadastrar.movimentacoes",
    path: "/almoxarifado/movimentacoes/cadastrar",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/GerenciarMovimentacoesAlmoxarifado.vue"),
    name: "almoxarifado.gerenciar.movimentacoes",
    path: "/almoxarifado/movimentacoes/gerenciar",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/VisualizarMovimentacaoAlmoxarifado.vue"),
    name: "almoxarifado.visualizar.movimentacoes",
    path: "/almoxarifado/movimentacoes/visualizar/:id",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/HistoricoProdutosAlmoxarifado.vue"),
    name: "almoxarifado.visualizar.historico",
    path: "/almoxarifado/movimentacoes/historico/:id",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/MenuProdutosAlmoxarifado.vue"),
    name: "almoxarifado.menu.produtos",
    path: "/almoxarifado/menu/produtos",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado/MenuMovimentacoesAlmoxarifado.vue"),
    name: "almoxarifado.menu.movimentacoes",
    path: "/almoxarifado/menu/movimentacoes",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },

  //
  //
  //
  // ALMOXARIFADO 02
  //
  //
  //

  {
    component: () => import("@/components/almoxarifado-02/menu-produtos-almoxarifado.vue"),
    name: "novo-almoxarifado-02.menu.produtos",
    path: "/novo-almoxarifado-02/menu/produtos",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado-02/menu-movimentacoes-almoxarifado.vue"),
    name: "novo-almoxarifado-02.menu.movimentacoes",
    path: "/novo-almoxarifado-02/menu/movimentacoes",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },

  {
    component: () => import("@/components/almoxarifado-02/menu-almoxarifado.vue"),
    name: "novo-almoxarifado2.menu",
    path: "/novo-menu-almoxarifado-02",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },

  {
    component: () => import("@/components/almoxarifado-02/cadastro-produto-almoxarifado.vue"),
    name: "novo-almoxarifado-02.cadastro.produto",
    path: "/novo-almoxarifado-02/cadastro/produto",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado-02/gerenciar-produto-almoxarifado.vue"),
    name: "novo-almoxarifado-02.gerenciar.produtos",
    path: "/novo-almoxarifado-02/gerenciar/produtos",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado-02/cadastro-movimentacao-almoxarifado.vue"),
    name: "novo-almoxarifado-02.cadastrar.movimentacoes",
    path: "/novo-almoxarifado-02/cadastrar/movimentacoes",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
  {
    component: () => import("@/components/almoxarifado-02/gerenciar-movimentacoes-almoxarifado.vue"),
    name: "novo-almoxarifado-02.gerenciar.movimentacoes",
    path: "/novo-almoxarifado-02/gerenciar/movimentacoes",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },

  {
    component: () => import("@/components/almoxarifado-02/cadastro-produto-almoxarifado.vue"),
    name: "novo-almoxarifado-02.edit.produto",
    path: "/novo-almoxarifado-02/edit/produto/:id",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },

  {
    component: () => import("@/components/almoxarifado-02/cadastro-movimentacao-almoxarifado.vue"),
    name: "novo-almoxarifado-02.edit.movimentacao",
    path: "/novo-almoxarifado-02/edit/movimentacao/:id",
    meta: {
      authorize: [ADMIN_SYS, ALMOXARIFADO],
    },
  },
];
