import {
  ADMINISTRADOR,
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  CONTROLADORIA,
  EMISSOR_NFE,
  FATURAMENTO,
  FATURAMENTO_NFE,
  FINANCEIRO,
  GERENTE_OSORIO,
  LOGISTICA,
  FINANCEIRO_GERENTE,
  GESTAO,
  OPERACIONAL,
  EXPEDICAO,
  GUARITA,
} from "@/constants/profiles.js";

export default [
  {
    path: "/logistica",
    name: "logistica.home.logistica",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        EXPEDICAO,
        GUARITA,
      ],
    },
    component: () => import("@/components/home/MenuLogistica.vue"),
  },
  {
    path: "/logistica/cadastros",
    name: "logistica.home.cadastros",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        GERENTE_OSORIO,
        LOGISTICA,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/home-cadastros.vue"),
  },
  {
    path: "/logistica/cadastros/transportadoras/cadastro",
    name: "transportadoras.cadastro",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/transportadora/form-transportadora.vue"),
  },
  {
    path: "/logistica/cadastros/transportadoras/editar/:id",
    name: "transportadoras.edit",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/transportadora/form-transportadora.vue"),
  },
  {
    path: "/logistica/cadastros/transportadoras",
    name: "transportadoras",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/transportadora/transportadoras.vue"),
  },
  {
    path: "/logistica/cadastros/fretes/cadastro",
    name: "fretes.cadastro",
    meta: {
      authorize: [ADMIN_SYS, LOGISTICA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/fretes/novo-frete.vue"),
  },
  {
    path: "/logistica/cadastros/fretes/cadastro/:id",
    name: "fretes.cadastro.editar",
    meta: {
      authorize: [ADMIN_SYS, LOGISTICA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/fretes/novo-frete.vue"),
  },
  {
    path: "/logistica/cadastros/fretes",
    name: "fretes",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/fretes/gerenciar-fretes.vue"),
  },
  {
    path: "/logistica/cadastros/rotas/cadastro",
    name: "rotas.cadastro",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/rotas/nova-rota.vue"),
  },
  {
    path: "/logistica/cadastros/rotas/cadastro/:id",
    name: "rotas.cadastro.editar",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/rotas/nova-rota.vue"),
  },
  {
    path: "/logistica/cadastros/rotas",
    name: "rotas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/rotas/gerenciar-rotas.vue"),
  },
  {
    path: "/logistica/cte",
    name: "logistica.home.cte",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cte/home-cte.vue"),
  },
  {
    path: "/logistica/cte/novo",
    name: "logistica.cte.novo",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cte/cte-novo.vue"),
  },
  {
    path: "/logistica/cte/editar/:id",
    name: "logistica.cte.edit",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cte/cte-novo.vue"),
  },
  {
    path: "/logistica/cte-show/:id",
    name: "logistica.cte.show",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cte/cte-show.vue"),
  },
  {
    path: "/logistica/gerenciar-cte",
    name: "logistica.gerenciar-cte",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cte/gerenciar-cte.vue"),
  },
  {
    path: "/logistica/mdfe",
    name: "logistica.home.mdfe",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/home-mdfe.vue"),
  },
  {
    path: "/logistica/gerenciar-mdfe",
    name: "logistica.gerenciar-mdfe",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/gerenciar-mdfe.vue"),
  },
  {
    path: "/logistica/mdfe-show/:id",
    name: "logistica.mdfe.show",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/mdfe-show.vue"),
  },
  {
    path: "/logistica/mdfe/editar/:id",
    name: "logistica.mdfe.edit",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/novo-mdfe.vue"),
  },
  {
    path: "/logistica/novo-mdfe",
    name: "logistica.novo-mdfe",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/novo-mdfe.vue"),
  },
  {
    path: "/logistica/mdfe",
    name: "logistica.home.mdfe",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/home-mdfe.vue"),
  },
  {
    path: "/logistica/gerenciar-mdfe",
    name: "logistica.gerenciar-mdfe",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/gerenciar-mdfe.vue"),
  },
  {
    path: "/logistica/mdfe-show/:id",
    name: "logistica.mdfe.show",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/mdfe-show.vue"),
  },
  {
    path: "/logistica/mdfe/editar/:id",
    name: "logistica.mdfe.edit",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/novo-mdfe.vue"),
  },
  {
    path: "/logistica/novo-mdfe",
    name: "logistica.novo-mdfe",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO],
    },
    component: () => import("@/components/logistica/mdfe/novo-mdfe.vue"),
  },
  {
    path: "/logistica/romaneio",
    name: "logistica.home.romaneio",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        FINANCEIRO_GERENTE,
        EXPEDICAO,
        GUARITA,
      ],
    },
    component: () => import("@/components/logistica/romaneio/home-romaneio.vue"),
  },
  {
    path: "/logistica/romaneio/gerenciar",
    name: "logistica.gerenciar-romaneio",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        FINANCEIRO_GERENTE,
        EXPEDICAO,
        GUARITA,
      ],
    },
    component: () => import("@/components/logistica/romaneio/gerenciar-romaneio.vue"),
  },
  {
    path: "/logistica/romaneio/gerenciar/emitir",
    name: "logistica.novo-romaneio",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/romaneio/novo-romaneio.vue"),
  },
  {
    path: "/logistica/romaneio/gerenciar/emitir/:id",
    name: "logistica.novo-romaneio.editar",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/romaneio/novo-romaneio.vue"),
  },
  {
    path: "/logistica/cadastros/motoristas/cadastro",
    name: "motorista.cadastro",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/motorista/form-motorista.vue"),
  },
  {
    path: "/logistica/cadastros/motoristas/editar/:id",
    name: "motorista.edit",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/motorista/form-motorista.vue"),
  },
  {
    path: "/logistica/cadastros/motoristas",
    name: "motoristas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/motorista/motorista.vue"),
  },
  {
    path: "/logistica/cadastros/veiculos/cadastro",
    name: "veiculo.cadastro",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/veiculo/form-veiculo.vue"),
  },
  {
    path: "/logistica/cadastros/veiculos/editar/:id",
    name: "veiculo.edit",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, FINANCEIRO_GERENTE],
    },
    component: () => import("@/components/logistica/cadastros/veiculo/form-veiculo.vue"),
  },
  {
    path: "/logistica/cadastros/veiculos",
    name: "veiculo",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/veiculo/veiculo.vue"),
  },
  {
    path: "/logistica/cadastros/empresas-gerenciar",
    name: "empresas.gerenciar",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LOGISTICA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cadastros/empresas/gerenciar-empresa.vue"),
  },
  {
    path: "/logistica/dashboard/otif",
    name: "logistica.dashboard.otif",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, LOGISTICA, GERENTE_OSORIO, CONSULTA_FINANCEIRO],
    },
    component: () => import("@/components/logistica/dashboard/dashboard-otif.vue"),
  },
];
